import { useGetPpmApplicationQuery } from "__generated__/types";
import { useOrder } from "store";

export function usePpmApplication() {
  const { id: orderId } = useOrder();
  const { data, loading, error } = useGetPpmApplicationQuery({
    variables: {
      orderId: orderId!,
    },
    fetchPolicy: "cache-and-network",
  });

  const ppmApplication = data?.order?.moveTaskOrder?.ppmApplication;
  
  return {
    loading,
    error,
    ppmApplication,
  };
}
